<template>
  <div class="businessCooperation">
    <div class="container">
      <h1>商务合作</h1>
      <ul class="dashed_container">
        <li v-for="(item, index) in cooperationList_one" :key="index">
          <h2>{{item.mdTitle}}</h2>
          <p>{{item.tel}}</p>
          <p>{{item.email}}</p>
        </li>
      </ul>
      <h2>影院合作</h2>
      <ul>
        <li v-for="(item, index) in cooperationList_two" :key="index">
          <p>{{item.place}}</p>
          <p>{{item.tel}}</p>
          <p>{{item.email}}</p>
        </li>
      </ul>
    </div>
    <div class="bottom">
      <div class="bottom_container">
        <h1>合作伙伴</h1>
        <table cellspacing="10px">
          <tr v-for="(item, index) in commpanyImg" :key="index">
            <td colspan="4">
              <img :src="item.one" />
            </td>
            <td colspan="4">
              <img :src="item.two" />
            </td>
            <td colspan="4">
              <img :src="item.three" />
            </td>
            <td colspan="4">
              <img :src="item.four" />
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      cooperationList_one: [
        {
          mdTitle: "商务、营销、广告合作",
          tel: "电话：0755-26971933",
          email: "邮箱：roy@maizuo.com"
        },
        {
          mdTitle: "电影营销合作",
          tel: "电话：0755-26009644",
          email: "邮箱：Pang@maizuo.com"
        },
        {
          mdTitle: "新媒体（微信/微博）合作",
          tel: "QQ：2696474864",
          email: "邮箱：yy@maizuo.com"
        },
        {
          mdTitle: "线下资源合作",
          tel: "QQ：983069039",
          email: "邮箱：mikexiang@maizuo.com"
        }
      ],
      cooperationList_two: [
        {
          place: "深圳站",
          tel: "电话：0755-26971933",
          email: "邮箱：roy@maizuo.com"
        },
        {
          place: "广州站",
          tel: "电话：020-38200507",
          email: "邮箱：Jeb@maizuo.com"
        },
        {
          place: "佛山站",
          tel: "电话：0757--82500640",
          email: "邮箱：Safin@maizuo.com"
        },
        {
          place: "区域代理合作（除广州、深圳、佛山之外的城市）",
          tel: "电话：0755-26939217",
          email: "邮箱：roy@maizuo.com"
        }
      ],
      commpanyImg: [
        {
          one: 'http://mall.s.maizuo.com/8d098b02067a627b04aa43bbb6a1c488.jpg',
          two: 'https://mall.s.maizuo.com/608a9c4a62aa68ca6d465dba71e855be.jpg',
          three: 'https://mall.s.maizuo.com/5f2ef8fa1a417d7dd95e6cfb02d07b6a.jpg',
          four: 'http://mall.s.maizuo.com/f30aa4373782a805a02b58cb33e2d271.jpg'
        },
        {
          one: 'https://mall.s.maizuo.com/6f5b34d9bd189e6f8065beceab6bb209.jpg',
          two: 'http://mall.s.maizuo.com/e6cd822ad789807f78ee2511176c5882.jpg',
          three: 'http://mall.s.maizuo.com/ce9d360de1db36400aa13729417ef836.jpg'
        }
      ]
    };
  }
};
</script>

<style lang="less">
.businessCooperation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .container {
    padding-top: 60px;
    margin-bottom: 40px;
    width: 1040px;
    h1 {
      margin: 0 0 22px 0;
      padding: 0;
      color: #222426;
      font-size: 36px;
      padding-left: 76px;
      font-weight: normal;
    }
    h2 {
      margin: 0;
      padding: 0 0 0 80px;
      font-size: 16px;
      color: #ff5f16;
    }
    ul {
      margin: 0;
      padding: 0;
      overflow: hidden;
      li {
        list-style: none;
        float: left;
        width: 188px;
        margin: 22px 78px;
        h2 {
          padding: 0;
          margin-bottom: 15px;
        }
        p {
          margin: 0;
          line-height: 24px;
          font-weight: normal;
        }
      }
    }
    .dashed_container {
      position: relative;
      margin-bottom: 34px;
      &::after {
        content: "";
        width: 886px;
        height: 3px;
        position: absolute;
        bottom: 0;
        left: 78px;
        border-bottom: 1px dashed #d2d2d2;
      }
    }
  }
  .bottom {
    width: 100%;
    border-top: 1px solid #e6e6e6;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .bottom_container {
      h1 {
        margin: 60px 0 35px 0;
        padding: 0;
        color: #222426;
        font-size: 36px;
        padding-left: 10px;
        font-weight: normal;
      }
      table {
        font-size: 0;
        margin-bottom: 148px;
        tr {
          &:last-child {
            td {
              &:last-child {
                display: none;
              }
            }
          }
          td {
            width: 210px;
            height: 90px;
            background-color: #fff;
            text-align: center;
            border: 1px dashed #e6e6e6;
          }
        }
      }
    }
  }
}
</style>

